.paperdesign1 {
    position: relative;
  }
.paperdesign2 {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

  .paperdesign2 div:nth-child(1) {
    width: 100%;
    /* height: 70%; */
  }

  .paperdesign2 div div {
    display: flex !important;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }