.dashboard{
    margin-top: 1rem;
    height: 100%;
    padding: 0px 60px !important; 
    
}

.badgescard{
    padding: 1rem;
}

.reputationPortion{
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.reputation p{
    font-size: 12px;
}

.goldBadgesportion{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.goldBadges{
    display: flex;
    gap: 10px;

}

.goldBadgesRepu{
    font-size: 12px;
}

.goldBadgesFamQuestionPortion{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.famousQuestionLeft{
    display: flex;
    align-items: center;
    gap:5px;
}

 .famousQuestionLeft .mybadge::before{
    content: "•";
    color: yellow;
    font-size: 15px;
}

.famousQuestionLeftQuan{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
}

.famousQuestionRight{
    font-size: 12px;
    margin-bottom: 0px;
}


.silverBadgesportion{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.silverBadges{
    display: flex;
    gap: 10px;

}

.silverBadgesRepu{
    font-size: 12px;
}

.silverBadgesFamQuestionPortion{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}


.questionLeft{
    display: flex;
    align-items: center;
    gap:5px;
}

 .questionLeft .mybadge::before{
    content: "•";
    color: yellow;
    font-size: 15px;
}


.questionRight{
    font-size: 12px;
    margin-bottom: 0px;
}


.bornzeBadgesportion{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bornzeBadges{
    display: flex;
    gap: 10px;

}

.bornzeBadgesRepu{
    font-size: 12px;
}

.bornzeBadgesFamQuestionPortion{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}


/* chart */

.barChartDateRange{
   margin: 10px !important;

}

.filterButton{
    margin-top: 25px;
}

.montSelector{
    /* text-align: center;
    justify-content: center; */
    margin: 10px;
     /* width: 50%; */
} 

.chartDropdown{
     margin: 15px;
}

@media (max-width: 480px) {
    .ant-calendar-range {
        width: 320px;
    }
    .ant-calendar-range-part {
        width: 100%;
    }
}