.modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  .modal-fullscreen {
    margin: 1rem auto !important;
  }
  body {
    font-family: 'Arial', sans-serif;
  }
  
  /* Styles for the table */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  
  th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2; /* Header background color */
  }
  
  /* Container styles */
  #invoiceCapture {
    position: relative;
  }