@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100vh;
}

body {  
  line-height: 1.6;
  background-color: ghostwhite !important;

  color: #333;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
}

.row-scrollers::-webkit-scrollbar {
  display: none;
}

.blink-text  {
  animation: animate 
      1.5s ease-in-out infinite;
}
@keyframes animate {
  0% {
      opacity: 0.7;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0.7;
  }
}

