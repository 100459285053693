/* @import url('https://fonts.googleapis.com/css?family=Overpass:400,600,700');


.select {
    width: 240px;
    height: 40px;
    padding: 0 16px;
    border-radius: 6px;
    border:1px solid rgb(243, 220, 220);
    position: relative;
    cursor: pointer;
  }
  
  .selected-option {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 14px;
    }
    i {
      transform: translateX(43%);
    }
  }
  
  .options {
    position: absolute;
    top:50px;
    left:0;
    width: 240px;
    max-height: 249px;
    overflow-y: scroll;
    padding: 0 8px 8px 8px;
    border-radius: 6px;
    border:1px solid rgb(243, 220, 220);
    ul {
      margin-top: 48px;
    }
    li {
      height: 32px;
      font-size: 14px;
      list-style: none;
      line-height: 36px;
      padding: 0 10px;
      cursor: pointer;
      border-radius: 6px;
      color:rgb(175, 161, 161);
      transition: 0.3s linear;
      &:hover {
        color: #000;
        background: rgba(10, 39, 206, 0.082);
      }
      &.active-option {
        color: #000;
        background: rgba(10, 39, 206, 0.082);
      }
    }
  }
  
  .search-options {
    padding-top: 8px;
    background: #fff;
    position: fixed;
    height: 40px;
    width: 208px;
  }
   */
  /* input {
      width: calc(240px - 32px);
      height: 32px;
      font-size: 14px;
      line-height: 32px;
      padding: 0 10px;  
      margin-bottom: 8px;
      border-radius: 6px;
      position: fixed;
      border:1px solid rgb(243, 220, 220);
      
  } */

  .rbt2 {
    position: static !important;
    display: flex !important;
    /* width: 180px !important; */
  }

  .new-rbt2 {
    position: static !important;
    display: flex !important;
    justify-content: center !important;
  }

  .new-rbt2 button {
    display: flex  !important;
    align-items: center  !important;
    justify-content: center  !important;
  }

  .rbt2 .rbt-aux {
    position: relative;
  }